import React from 'react';
import './Card.css';
import selosData from './selos.json';  // Ajuste o caminho conforme necessário


const Status = {
status: "Pré-candidato/a "

}

const Card = ({ nome, cidade, estado, partido, genero, candidato, redes, selos, foto }) => {
  const selosToImg = selosData;
  const seloImgSrc = selos ? selosToImg[selos.trim()] : null;

  return (

    <section className="container">
      <div className="container-img">
    
       <img className='card-image' src={foto} alt="Foto do candidato"/>

      </div>
      <div className="container-info">
        <h3 className="title-text"> {nome}</h3>
        <h4 className="subtitle-text"><span>{Status.status}</span>a {candidato}</h4>
        <h4 className="part-text"> {cidade} - {estado}</h4>
        <h4 className="part-text"> Gênero: {genero}</h4>
        <h4 className="part-text">Partido: {partido}</h4>
        {/* <p className="info-text">
          Idade: {faixaEtaria} <span className="bar-color">|</span> Gênero: {genero} <span className="bar-color">|</span> Raça: {raca}
        </p> */}
        {/* <p className="body-text">
          {biography ? biography : 'Biografia não disponível'}
        </p> */}
        <article className="social-container">
          <a href={redes} target="_blank" rel="noopener noreferrer">
            <svg width="50" height="50" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.412598" y="0.00854492" width="19.1328" height="19.1328" rx="4.25173" fill="#F23000" fillOpacity="0.06" />
              <path fillRule="evenodd" clipRule="evenodd" d="M5.1958 9.57494C5.1958 7.66425 5.1958 6.7089 5.6511 6.02079C5.85442 5.71352 6.11757 5.45036 6.42484 5.24705C7.11295 4.79175 8.0683 4.79175 9.97899 4.79175C11.8897 4.79175 12.845 4.79175 13.5331 5.24705C13.8404 5.45036 14.1036 5.71352 14.3069 6.02079C14.7622 6.7089 14.7622 7.66425 14.7622 9.57494C14.7622 11.4856 14.7622 12.441 14.3069 13.1291C14.1036 13.4364 13.8404 13.6995 13.5331 13.9028C12.845 14.3581 11.8897 14.3581 9.97899 14.3581C8.0683 14.3581 7.11295 14.3581 6.42484 13.9028C6.11757 13.6995 5.85442 13.4364 5.6511 13.1291C5.1958 12.441 5.1958 11.4856 5.1958 9.57494ZM12.4552 9.57504C12.4552 10.9427 11.3465 12.0514 9.9789 12.0514C8.61127 12.0514 7.50258 10.9427 7.50258 9.57504C7.50258 8.20741 8.61127 7.09872 9.9789 7.09872C11.3465 7.09872 12.4552 8.20741 12.4552 9.57504ZM9.9789 11.2135C10.8838 11.2135 11.6174 10.48 11.6174 9.57504C11.6174 8.67011 10.8838 7.93653 9.9789 7.93653C9.07397 7.93653 8.34039 8.67011 8.34039 9.57504C8.34039 10.48 9.07397 11.2135 9.9789 11.2135ZM12.553 7.55618C12.8744 7.55618 13.1349 7.29567 13.1349 6.97432C13.1349 6.65297 12.8744 6.39246 12.553 6.39246C12.2317 6.39246 11.9712 6.65297 11.9712 6.97432C11.9712 7.29567 12.2317 7.55618 12.553 7.55618Z" fill="#F23000" />
            </svg>
          </a>
          {seloImgSrc && (
            <a target="_blank" rel="noopener noreferrer">
              <img className='img-selos' src={seloImgSrc} alt="Selo" />
            </a>
          )}
        </article>
      </div>
    </section>
  );
};

export default Card;
