import React, { useState } from 'react';
import { scroller } from 'react-scroll';
import estadosCidades from './estados-cidades.json';
import './Filter.css';

const Filter = ({ onSave }) => {
    const [selectedEstado, setSelectedEstado] = useState('');
    const [selectedCidade, setSelectedCidade] = useState('');
    const [cidades, setCidades] = useState([]);

    const handleEstadoChange = (e) => {
        const estadoSigla = e.target.value;
        setSelectedEstado(estadoSigla);
        setSelectedCidade('');
        
        const estadoSelecionado = estadosCidades.estados.find(estado => estado.sigla === estadoSigla);
        if (estadoSelecionado && estadoSelecionado.cidades) {
            setCidades(estadoSelecionado.cidades);
        } else {
            setCidades([]);
        }
    };

    const handleCidadeChange = (e) => {
        setSelectedCidade(e.target.value);
    };

    const scroll_bottom = () => {
        scroller.scrollTo('scrolling-box', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
    };

    const handleSave = () => {
        onSave({
            estado: selectedEstado,
            cidade: selectedCidade
        });
        setTimeout(scroll_bottom, 0); // Garante que a rolagem ocorra após a atualização do estado
    };

    return (
        <div className="Filter">
            <select id="estado" onChange={handleEstadoChange} value={selectedEstado}>
                <option value="">Selecione um estado</option>
                {estadosCidades.estados.map((estado) => (
                    <option key={estado.sigla} value={estado.sigla}>
                        {estado.nome}
                    </option>
                ))}
            </select>
            <br />
            <select id="cidade" onChange={handleCidadeChange} value={selectedCidade} disabled={!selectedEstado}>
                <option value="">Selecione uma cidade</option>
                {cidades.map((cidade, index) => (
                    <option key={index} value={cidade}>
                        {cidade}
                    </option>
                ))}
            </select>
            <br />
            <button className='button-lupa' onClick={handleSave}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.4957 17.9469L15.7878 14.2499C16.9841 12.7258 17.6332 10.8437 17.6308 8.9062C17.6308 7.18066 17.1191 5.49388 16.1605 4.05915C15.2018 2.62442 13.8392 1.50618 12.2451 0.845846C10.6509 0.185512 8.89667 0.0127389 7.20429 0.349374C5.51191 0.686009 3.95736 1.51693 2.73722 2.73707C1.51709 3.95721 0.686162 5.51176 0.349527 7.20414C0.0128915 8.89652 0.185665 10.6507 0.845999 12.2449C1.50633 13.8391 2.62457 15.2017 4.0593 16.1603C5.49403 17.119 7.18081 17.6307 8.90635 17.6307C10.8439 17.6331 12.726 16.9839 14.2501 15.7876L17.9471 19.4955C18.0485 19.5977 18.1691 19.6789 18.302 19.7342C18.4349 19.7896 18.5774 19.8181 18.7214 19.8181C18.8653 19.8181 19.0079 19.7896 19.1408 19.7342C19.2737 19.6789 19.3943 19.5977 19.4957 19.4955C19.5979 19.3941 19.679 19.2735 19.7344 19.1406C19.7897 19.0077 19.8182 18.8652 19.8182 18.7212C19.8182 18.5772 19.7897 18.4347 19.7344 18.3018C19.679 18.1689 19.5979 18.0483 19.4957 17.9469ZM2.363 8.9062C2.363 7.61205 2.74676 6.34696 3.46576 5.27091C4.18475 4.19486 5.20668 3.35618 6.40232 2.86093C7.59796 2.36568 8.91361 2.2361 10.1829 2.48858C11.4522 2.74106 12.6181 3.36425 13.5332 4.27935C14.4483 5.19446 15.0715 6.36037 15.324 7.62965C15.5764 8.89894 15.4469 10.2146 14.9516 11.4102C14.4564 12.6059 13.6177 13.6278 12.5416 14.3468C11.4656 15.0658 10.2005 15.4495 8.90635 15.4495C7.17095 15.4495 5.50662 14.7602 4.27951 13.533C3.05239 12.3059 2.363 10.6416 2.363 8.9062Z" fill="white"/>
                </svg>
            </button>
        </div>
    );
};

export default Filter;
