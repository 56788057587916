import React from 'react';
import Filter from './Filter';
import './Banner.css';

const Banner = ({ onSave }) => {
    return (
        <div className="intro" id='filter'>
            <h1 className="center" ><img className='vozes-img' src='/images/voz.png' /></h1>
            <p>Faça uma busca para saber sobre quem são os canditados da sua região</p>
            <div>
                <Filter onSave={onSave} />            
            </div>
        </div>
    );
}

export default Banner;
