import './Header.css';

const Header = () => {
    return (
        <header className="header">
            <nav>
                <div className="logo">
                    <a href="https://ypykuera.org.br/"><img className="Header_logo" src="./images/logo.png" /></a>
                </div>
                <input type="checkbox" id="menu-toggle"/>
                    <label  htmlFor="menu-toggle" className="menu-icon">&#9776;</label>
                    <ul className="menu">
                        <li><a className='list-menu-effect' href="https://ypykuera.org.br/">SOBRE</a></li>
                        <li><a className='list-menu-effect'href="https://agendasparaobrasil.org.br/">AGENDAS PARA O BRASIL</a></li>
                        <li className="button" ><a href="https://ypykuera.org.br/contato/">CONTATO!</a></li>
                        
                    </ul>
            </nav>
        </header>
       
    );
}

export default Header;